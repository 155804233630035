import React from "react"
import Layout from '../components/Layout'
import { Row, Col } from "react-bootstrap"
import { Helmet } from "react-helmet"
import * as data from '../data/home-data'
import { Link } from "gatsby"

export default function Home() {

  return (
    <Layout page="home">
      <Helmet>
        <body className="home-body" />
        <meta charSet="utf-8" />
        <meta name="description" content={data.metaData.meta_description} /> 
        <title>{data.metaData.meta_title}</title>
      </Helmet>
      <h1 className="heading">{data.metaData.heading}</h1>
      <p className="description">{data.metaData.description}</p>
    
      <div className="spaces">
          <Row className="services">

            {data.columns.map((block, index) => {
              return(
                <Col md={6} key={index}>
                  <p className="title">{block.title}</p>
                  <ul className="service">
                    {block.menu.map((bloc) => {
                      return(
                        <li key={bloc.title}>
                          <Link className="service-link" to={bloc.link}>
                            {bloc.title} {bloc.badge ? <span className="badge badge-warning">Hot</span> : null}
                            <div className="svg-container">
                              <svg className="service-arrow" width="24" height="24" viewBox="0 0 32 32"><g fillRule="evenodd"><path transform="scale(-1 1) rotate(45 -11.999 -25.642)" d="M5.364 -0.236H7.364V15.764H5.364z"></path><path transform="rotate(-135 15.163 15.994)" d="M5.364 9.764H7.364V25.764H5.364z"></path><path d="M2 14H26V16H2z" transform="matrix(-1 0 0 1 29 1)"></path></g></svg>
                            </div>
                            <span className="d-md-none d-lg-none d-xl-none"><svg width="9" height="15" viewBox="0 0 9 15"><path transform="rotate(-45 5.036 12.157)" d="M10.071068 0.0710678L10.071068 10.0710678 0.071068 10.0710678" stroke="currentColor" fill="none" fillRule="evenodd"></path></svg></span>
                          </Link>
                        </li>                  
                      )})}
                  </ul>
                </Col>
              )})}
          </Row>
      </div>
      <footer className="container mt-5">
      <div className="row">
        <div className="col-6">
          {/* Left side links */}
          <div className="footer-links">
            <Link to="/terms">Terms</Link>|<Link to="/cancellation">Refund Policy</Link>
          </div>
        </div>
        <div className="col-6">
          {/* Right side copyright notice with text-right */}
          <div className="footer-links text-right">
            <p className="mb-0">Copyright &copy; {new Date().getFullYear()}. The Able Guys</p>
          </div>
        </div>
      </div>
    </footer>    
    </Layout>
  )
}
